<script>
import { mapActions, mapState } from "vuex";
import ListaReproduccion from "./ListaReproduccion.vue";
import { getYoutubeThumbnail } from "@/utils/youtube";

export default {
  components: { ListaReproduccion },
  props: {
    videos: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      videoDetail: {},
      videoToDelete: null,
    };
  },
  computed: {
    ...mapState("tvCorporativaModule", ["selectedMediaList"]),
  },
  watch: {
    selectedMediaList() {
      this.$emit("playlistChange", this.selectedMediaList);
    },
  },
  methods: {
    ...mapActions("tvCorporativaModule", ["removeSelectedMediaList", "setSelectedMediaList","deleteFileRepositorio"]),
    deleteVideoFromList(index) {
      this.removeSelectedMediaList(index);
    },
    handlePageChange(page) {
      this.pagination.click_handler(page);
    },
    viewDetail(video) {
      this.videoDetail = video;
    },
    embedUrl(url) {
      if (url.includes('embed')) return url;
      const videoId = url.split('v=')[1];
      return `https://www.youtube.com/embed/${videoId}`;
    },
    addVideo(data_) {
      this.setSelectedMediaList([data_]);
      this.$refs.closeModal.click();
    },
    getYoutubeThumbnail(url){
      return getYoutubeThumbnail(url);
    },
    triggerDeleteModal(video) {
      this.videoToDelete = video;
    },
    async deleteVideo(video) {
      this.show_spinner = true;
      const data = {
        type: "7",
        id_archivo: +video.id_archivo,
      };
      const res = await this.deleteFileRepositorio(data);
      this.show_spinner = false;
      this.open_question_modal = false;
      if (!res) {
        this.toastVideoDeleteFalla();
      } else {
        if (res.nombre_archivo.includes("in use")) {
          this.toastVideoDeleteEnUso(res.canales);
        } else {
          this.toastVideoDeleteExitoso();
          this.$emit("videoDeleted", res);
        }
      }
    },
    toastVideoDeleteEnUso(canales) {
      this.$toast.open({
        message: `No es posible eliminar este video, debes removerlo de los siguientes canales para continuar:<br/> <ul>${canales
          .map((txt) => `<li> &bullet; ${txt}</li>`)
          .join("")}</ul> `,
        type: "error",
        duration: 30000,
        position: "top-right",
      });
    },
    toastVideoDeleteFalla() {
      this.$toast.open({
        message:
          "Hubo errores al momento de eliminar video. Por favor inténtalo nuevamente",
        type: "error",
        duration: 6000,
        position: "top-right",
      });
    },
    toastVideoDeleteExitoso() {
      this.$toast.open({
        message: "El video ha sido eliminado exitosamente.",
        type: "success",
        duration: 6000,
        position: "top-right",
      });
    },

  },
};
</script>

<template>
  <section id="lista-videos-yt">
    <!-- Lista de videos -->
    <div class="row">
      <div v-for="(video, i) in videos" :key="i" class="col-6 col-xxl-4">
        <div class="card border-0 card-shadow card-item-add mb-3">
          <div class="card-body d-flex flex-column flex-xl-row">
            <div class="flex-shrink-0 text-center text-xl-start">
              <img
                :src="getYoutubeThumbnail(video.url_archivo)"
                class="img-fluid img-obj card-item-add-img rounded"
              />
            </div>
            <div
              class="card-item-add-content d-flex flex-column flex-grow-1 mt-3 mt-xl-0 ms-0 ms-xl-3"
            >
              <p>
                <strong>
                  {{ video.nombre_archivo }}
                </strong>
              </p>
                <p class="description-ellipsis">{{ video.descripcion_archivo }}</p>
            </div>
          </div>
          <div class="card-item-add-config">
            <a
              href="javascript:"
              @click="viewDetail(video)"
              class="card-item-add-config-btn"
              data-bs-toggle="modal"
              data-bs-target="#modal-detail-video-yt"
              content="Ver video"
              v-tippy="{ arrow: true }"
            >
              <font-awesome-icon icon="eye" />
            </a>
            <a
              href="javascript:"
              @click="addVideo(video)"
              class="card-item-add-config-btn"
              content="Seleccionar video"
              v-tippy="{ arrow: true }"
            >
              <font-awesome-icon icon="check" />
            </a>
            <a
              href="javascript:"
              @click="triggerDeleteModal(video)"
              data-bs-toggle="modal"
              data-bs-target="#modal-delete-video-yt"
              class="card-item-add-config-btn"
              content="Eliminar video"
              v-tippy="{ arrow: true }"
            >
              <font-awesome-icon icon="trash-alt" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Paginación -->
    <div class="row">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <paginate
          v-model="pagination.actual_page"
          :page-count="pagination.page_count"
          :page-range="pagination.page_range"
          :click-handler="pagination.click_handler"
          :prev-text="pagination.prev_text"
          :next-text="pagination.next_text"
          :container-class="pagination.container_class"
          :page-class="pagination.page_class"
        />
      </div>
    </div>
    <!-- Lista de reproducción -->
    <div class="row">
      <div class="col-12">
        <ListaReproduccion
          :selected_items="selectedMediaList"
          thumbnailField="url_imagen"
          @deleteItemFromList="deleteVideoFromList"
        />
      </div>
    </div>
    <!-- Modal para visualizar video de YouTube -->
    <div
      class="modal modal-custom fade"
      id="modal-detail-video-yt"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5">
              <font-awesome-icon icon="video" class="color-main pe-2" />
              <span> {{ videoDetail.nombre_archivo }}</span>
            </h1>
            <button
              type="button"
              class="modal-custom-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref="closeModal"
            >
              <font-awesome-icon icon="xmark" />
            </button>
          </div>
          <div class="modal-body">
            <div v-if="videoDetail.url_archivo" class="ratio ratio-16x9">
              <iframe
                width="560"
                height="315"
                :src="embedUrl(videoDetail.url_archivo)"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
              ></iframe>
            </div>
            <div
              v-if="videoDetail.descripcion_archivo"
              class="alert alert-light border mt-3 mb-0"
            >
              {{ videoDetail.descripcion_archivo }}
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              ref="closeModal"
              class="btn btn-custom-color-white rounded-pill mw-100"
              data-bs-dismiss="modal"
            >
              <i class="fa-solid fa-angle-left pe-1"></i>
              Volver
            </button>
            <button
              type="button"
              class="btn btn-custom-color-blue rounded-pill mw-100"
              @click="addVideo(videoDetail)"
              data-bs-dismiss="modal"
            >
              Añadir
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal para confirmar eliminación de video -->
    <div class="modal modal-custom fade" id="modal-delete-video-yt" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5">
              <font-awesome-icon icon="trash-alt" class="color-main pe-2" />
              Eliminar Video
            </h1>
            <button type="button" class="modal-custom-close" data-bs-dismiss="modal" aria-label="Close">
              <font-awesome-icon icon="xmark" />
            </button>
          </div>
          <div class="modal-body">
            ¿Estás seguro de eliminar este video?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-custom-color-white rounded-pill mw-100" data-bs-dismiss="modal">
              Cancelar
            </button>
            <button type="button" class="btn btn-custom-color-blue rounded-pill mw-100" data-bs-dismiss="modal" @click="deleteVideo(videoToDelete)">
              Eliminar
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style>
.description-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>